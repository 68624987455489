import { GenericObject } from "src/defs";

export const domainConstant = {
  AMERITAS_INTERNAL: "oj-ameritas.com",
  AMERITAS_ADE_EXTERNAL: "okta-ameritas.com",
  AMERITAS_EXTERNAL: "ameritas.com",
  GUARDIAN_INTERNAL: "oj-glic.com",
  GUARDIAN_EXTERNAL: "glic.com",
  HUMANA_INTERNAL: "oj-humana.com",
  HUMANA_ADE_EXTERNAL: "okta-humana.com",
  HUMANA_EXTERNAL: "humana.com",
  ELHEALTH_INTERNAL: "oj-elhealth.com",
  ELHEALTH_EXTERNAL: "elevancehealth.com",
  METLIFE_INTERNAL: "oj-metlife.com",
  METLIFE_EXTERNAL: "metlife.com",
  OVERJET_INTERNAL: "oj-overjet.com",
  PRINCIPAL_INTERNAL: "oj-principal.com",
  PRINCIPAL_EXTERNAL: "principal.com",
  UNUM_INTERNAL: "oj-unum.com",
  UNUM_EXTERNAL: "unum.com",
  DDC_EXTERNAL: "ddc.com",
  GOOGLE_INTERNAL: "oj-google.com",
  DENTAQUEST_INTERNAL: "oj-dentaquest.com",
  DENTAQUEST_EXTERNAL: "dentaquest.com",
  DDMO_INTERNAL: "oj-ddmo.com",
  UCD_INTERNAL: "oj-ucd.com",
  DDIL_INTERNAL: "oj-ddil.com",
  DDKY_INTERNAL: "oj-ddky.com",
  DDWI_INTERNAL: "oj-ddwi.com",
  DEMO_INTERNAL: "oj-demo.com",
  RENAISSANCE_INTERNAL: "oj-renaissance.com",
};

export const clientIdMappper: any = {
  glic: process.env.REACT_APP_GUARDIAN_EXTERNAL_SSO_CONFIG,
  "2": process.env.REACT_APP_GUARDIAN_EXTERNAL_SSO_CONFIG,
  overjet: process.env.REACT_APP_GUARDIAN_INTERNAL_SSO_CONFIG,
  humana: process.env.REACT_APP_HUMANA_EXTERNAL_SSO_CONFIG,
  "10": process.env.REACT_APP_HUMANA_INTERNAL_SSO_CONFIG,
  elevancehealth: process.env.REACT_APP_ELHEALTH_EXTERNAL_SSO_CONFIG,
  "21": process.env.REACT_APP_ELHEALTH_INTERNAL_SSO_CONFIG,
  "26": process.env.REACT_APP_GOOGLE_INTERNAL_SSO_CONFIG,
  ddc: process.env.REACT_APP_DDC_EXTERNAL_SSO_CONFIG,
  i0029: process.env.REACT_APP_DENTAQUEST_EXTERNAL_SSO_CONFIG,
};

// Used for internal uses E.g ADE;
export const identityIdMapper: GenericObject = {
  humana: process.env.REACT_APP_HUMANA_INTERNAL_SSO_CONFIG,
  elevancehealth: process.env.REACT_APP_ELHEALTH_INTERNAL_SSO_CONFIG,
  i0029: process.env.REACT_APP_DENTAQUEST_INTERNAL_SSO_CONFIG,
  glic: process.env.REACT_APP_GUARDIAN_INTERNAL_SSO_CONFIG,
};

export const ClientAccount: GenericObject = {
  AMERITAS: process.env.REACT_APP_AMERITAS_CLIENT_ACCOUNT_ID || "ameritas",
  HUMANA: process.env.REACT_APP_HUMANA_CLIENT_ACCOUNT_ID || "humana",
  METLIFE: process.env.REACT_APP_METLIFE_CLIENT_ACCOUNT_ID || "metlife",
  ELEVANCE:
    process.env.REACT_APP_ELHEALTH_CLIENT_ACCOUNT_ID || "elevancehealth",
  UNUM: process.env.REACT_APP_UNUM_CLIENT_ACCOUNT_ID || "unum",
  DDMO: "i0002",
  METLIFE_BM: "i0014",
  DDC: "ddc",
  DENTAQUEST: "dentaquest",
  RENAISSANCE: "i0031",
  DEMO: "i0999",
};

export const SWITCH_ACCOUNTS: GenericObject = {
  [ClientAccount.AMERITAS]: "Ameritas",
  [ClientAccount.DDMO]: "DDMO",
  [ClientAccount.HUMANA]: "Humana",
  [ClientAccount.METLIFE]: "Metlife",
  [ClientAccount.METLIFE_BM]: "Metlife BM",
  [ClientAccount.ELEVANCE]: "Elevance Health",
  [ClientAccount.UNUM]: "Unum",
  [ClientAccount.DDC]: "DDC",
  [ClientAccount.DENTAQUEST]: "DentaQuest",
  [ClientAccount.RENAISSANCE]: "Renaissance",
  [ClientAccount.DEMO]: "Demo",
};
